export const validateLegalAge = (value: string): boolean => {
  const today = new Date();
  const birthDate = new Date(value);
  if (!isValidDate(birthDate)) return false;
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  if (age < 18) {
    return false;
  }
  return true;
};

const isValidDate = date => {
  return (
    date &&
    Object.prototype.toString.call(date) === '[object Date]' &&
    !isNaN(date)
  );
};

export const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};
